.app {
  padding: 25px 25px 0 25px;
  background: var(--card-background);
  border: 1px solid var(--card-border-color);
  border-radius: 13px;
  margin-top: 16px;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
}
.app__delimiter {
  margin: 32px -25px 0 -25px;
}
.app__info {
  margin: 32px 0;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-right: 40px;
  gap: 50px;
  scrollbar-width: none;
}
.app__info::-webkit-scrollbar {
  display: none;
}
.app__open-btn--wide {
  margin-top: 24px;
  margin-bottom: 20px;
}
.app__open-btn--wide button {
  text-transform: uppercase;
}

@media screen and (max-width: 480px) {
  .app {
    padding: 24px 16px 0 16px;
    border: none;
    border-radius: 0;
    margin-left: -16px;
    margin-top: 12px;
    width: 100%;
    border-top: 1px solid var(--card-border-color);
    border-bottom: 1px solid var(--card-border-color);
  }
  .app__delimiter {
    margin: 24px -16px 24px -16px;
  }
  .app__screenshots {
    padding-right: 16px;
  }
}
.app-screenshots {
  margin: 25px -25px 32px -25px;
  padding-right: 25px;
  gap: 16px;
  overflow: auto;
  white-space: nowrap;
  display: flex;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
}
.app-screenshots::-webkit-scrollbar {
  display: none;
}
.app-screenshots:empty {
  display: none;
}

@media screen and (max-width: 480px) {
  .app-screenshots {
    margin-right: -16px;
    padding-right: 16px;
  }
}
.page-apps-navigation {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 10px;
}

.page-apps-navigation-item {
  padding-left: 10px;
  display: flex;
  align-items: center;
  height: 48px;
  color: var(--apps-navigation-inactive-color);
  cursor: pointer;
  transition: 0.1s ease-in-out;
  font-weight: 500;
}
.page-apps-navigation-item:hover {
  color: var(--apps-navigation-hover-color);
  text-decoration: none;
}
.page-apps-navigation-item__content {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}
.page-apps-navigation-item__name {
  margin-left: 16px;
  line-height: 24px;
}
.page-apps-navigation-item__icon {
  width: 24px;
  height: 24px;
}
.page-apps-navigation-item--active {
  color: var(--apps-navigation-icon-active);
}
.page-apps-navigation-item--active:hover {
  color: var(--apps-navigation-icon-active);
}
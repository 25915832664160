.source-viewer-nav {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: -1px;
  z-index: 800;
  overflow: auto;
  scrollbar-width: none;
}
.source-viewer-nav__tab {
  background: var(--code-viewer-tab-inactive-background);
  padding: 8px 18px 8px;
  border: 1px solid var(--code-viewer-border-color);
  border-left-width: 0;
  color: #666;
  overflow: hidden;
  font-size: 13px;
  white-space: nowrap;
  flex-shrink: 0;
  transition: 0.16s all ease;
}
.source-viewer-nav__tab:first-child {
  border-left-width: 1px;
  border-top-left-radius: 6px;
}
.source-viewer-nav__tab:last-child {
  border-top-right-radius: 6px;
}
.source-viewer-nav__tab--active {
  color: var(--body-text-color);
  background: var(--code-viewer-background);
  border-bottom-color: var(--code-viewer-background);
  pointer-events: none;
  z-index: 1000;
}
@media (hover: hover) {
  .source-viewer-nav__tab:hover {
    color: var(--body-text-color);
  }
}

.source-viewer-code {
  position: relative;
  background: var(--code-viewer-background);
  border: 1px solid var(--code-viewer-border-color);
  border-radius: 6px;
}
.source-viewer--tabbed .source-viewer-code {
  border-top-left-radius: 0;
}
.source-viewer-code__copy {
  position: absolute !important;
  right: 5px;
  top: 6px;
  z-index: 1200;
  background: var(--code-viewer-background);
  padding: 5px 8px 6px 5px;
  border-radius: 6px;
}
.source-viewer-code__pre {
  margin: 8px 14px;
  padding: 0;
  z-index: 900;
  white-space: pre;
  word-wrap: break-word;
  word-break: break-all;
  overflow: auto;
  font-family: "Ubuntu Mono", monospace;
  font-size: 15px;
  line-height: 25px;
}
.source-viewer-code__pre--base64, .source-viewer-code__pre--hex {
  white-space: pre-wrap;
}
.source-viewer-code__skeletons .skeleton {
  height: 1em;
  margin: 1em;
}
.ui-looong {
  white-space: nowrap;
  font-feature-settings: "tnum";
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100%;
  min-width: 0;
  font-size: 0;
}
.ui-looong::before, .ui-looong::after {
  font-size: 14px;
}
.ui-looong::before {
  flex-grow: 0;
  content: attr(data-loopa);
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui-looong::after {
  flex-shrink: 0;
  content: attr(data-poopa);
}

.address-link {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
}
.address-link.clickable, a > .address-link {
  color: var(--blue-bright);
}
.address-link.clickable:hover, a > .address-link:hover {
  border-bottom-color: currentColor;
}
.address-link.muted {
  opacity: 0.65;
  pointer-events: none;
}
.address-link:hover {
  text-decoration: none;
}
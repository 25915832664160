.header {
  display: flex;
  background: var(--header-background);
  box-shadow: 0 1px 10px var(--header-box-shadow-color);
  padding: 8px 0;
  margin-bottom: 18px;
  height: 55px;
  border-bottom: 1px solid var(--header-border-bottom-color);
  box-sizing: border-box;
  position: relative;
  z-index: 80000;
}
.header__container {
  flex-grow: 1;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  width: 46px;
  height: 46px;
  margin: -10px 0;
  position: relative;
  bottom: -1px;
  color: var(--header-logo-color);
  transition: 0.1s color ease;
}
.header__logo:hover {
  color: var(--body-text-color);
}
.header--mobile-search-visible .header__logo {
  display: none;
}

.header-ear-right {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}
.header-ear-right__buttons {
  display: flex;
  align-items: center;
}
.header-ear-right__button {
  cursor: pointer;
  color: #797979;
  padding: 16px 4px 16px 12px;
  margin: -12px -2px;
  fill: currentColor;
  width: 24px;
  height: 24px;
}
.header-ear-right__button:hover {
  color: #444;
}
.header-ear-right__button--loopa {
  display: none;
  z-index: 80010;
}
.header-ear-right__button--settings {
  z-index: 80001;
}

@media screen and (max-width: 1200px) {
  .header__container {
    padding: 0 8px;
  }
}
.header-search {
  position: relative;
  width: auto;
  font-size: 14px;
}
.header-search .search-input {
  min-width: 340px;
  background: var(--body-light-muted-color);
  color: var(--body-text-color);
  border-radius: 6px;
  border: 2px solid transparent;
  transition: 0.12s min-width ease, 0.12s border-color ease;
}
.header-search .search-input__loopa {
  margin: 0 -8px 0 8px;
  opacity: 0.3;
}
.header-search .search-input__input {
  padding: 9px 0 9px 16px;
}
.header-search .search-input__input::placeholder {
  color: #939394;
}
.header-search .search-input__input::-webkit-input-placeholder {
  color: #939394;
}
.header-search .search-input--expanded {
  min-width: 480px;
}
.header-search .search-input:focus-within {
  min-width: 480px;
  outline: 0 none;
}
.header-search .search-input-controls__loader {
  transform: scale(0.9);
}
.header-search .search-results {
  padding-bottom: 4px;
  padding-top: 4px;
  overflow: hidden;
}
.header-search .search-results__link {
  margin: 0 0 0 0;
}
.header-search .search-result__left {
  margin-left: -4px;
}

@media screen and (max-width: 480px) {
  .header {
    margin-bottom: 12px;
  }
  .header-search {
    flex-grow: 1;
  }
  .header-search .search-input {
    width: 100%;
    max-width: 100%;
    min-width: 0;
  }
  .header-search .search-input:focus-within {
    max-width: 100%;
    width: 100%;
    min-width: 0;
  }
  .header-search {
    display: none;
  }
  .header--mobile-search-visible .header-search {
    display: flex !important;
  }
  .header--mobile-search-visible .header-ear-right__buttons {
    display: none;
  }
  .header-ear-right__button--loopa {
    display: flex;
  }
}
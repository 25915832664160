.file-list {
  margin: 0;
  padding: 1px 0 0 0;
  list-style: none;
}
.file-list > .file-list {
  margin-left: 12px;
}
.file-list + .file-list {
  margin-top: 12px;
}
.file-list__file {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 4px;
  border: 1px solid transparent;
  position: relative;
}
.file-list__file:hover, .file-list__file--active {
  background: var(--code-viewer-file-selector-background-color);
  border-color: var(--code-viewer-file-selector-border-color);
}
.file-list__file::before {
  content: "";
  width: 2px;
  height: 0;
  background: var(--blue-bright);
  position: absolute;
  left: 3px;
  border-radius: 4px;
  transition: 0.15s height ease;
}
.file-list__file--active::before {
  height: 70%;
}
.file-list__file__svg {
  fill: currentColor;
  margin-right: 6px;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.file-list__file--directory .file-list__file__svg {
  opacity: 0.7;
  padding-left: 1px;
}
.file-list__file__name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}
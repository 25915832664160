.nft-collection-error-message {
  display: block;
  text-align: center;
  padding: 16px;
  max-width: 480px;
  margin: 100px auto;
}
.nft-collection-error-message__title {
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -1px;
  margin: 0;
  padding: 0;
}
.nft-collection-error-message__message {
  color: #909097;
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;
}
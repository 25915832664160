.validation-status-table {
  padding: 0 16px 12px 16px;
  border-spacing: 0;
}
.validation-status-table th {
  text-align: left;
  font-weight: normal;
  opacity: 0.4;
  padding: 3px;
}
.validation-status-table td {
  padding: 3px;
}
.validation-status-table th:first-child,
.validation-status-table td:first-child {
  padding-left: 0;
}
.validation-status-table th:last-child,
.validation-status-table td:last-child {
  padding-right: 0;
}
.ui-copy-button {
  position: relative;
  cursor: text;
}
.ui-copy-button__icon {
  fill: currentColor;
  user-select: none;
  opacity: 0.65;
  cursor: pointer;
  padding: 6px;
  margin: -6px -6px -8px -4px;
  width: 14px;
  height: 14px;
}
.ui-copy-button:hover .ui-copy-button__icon {
  opacity: 1;
}
.clickable + .ui-copy-button {
  margin-left: 0.5ex;
}
.app-card {
  display: flex;
  border-radius: 12px;
  border: 1px solid var(--card-border-color);
  background: var(--card-background);
  cursor: pointer;
  min-height: 85px;
  z-index: 1;
}
.app-card__title {
  color: var(--body-text-color);
  font-weight: 500;
}
.app-card__icon {
  border-radius: 12px;
  width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
}
.app-card__icon .broken-icon {
  color: #959a9e;
  width: 75%;
  height: 75%;
}
.app-card__load {
  background: var(--skeleton-loader-background);
}
.app-card__icon-wrapper {
  width: 85px;
  flex-shrink: 0;
}
.app-card__heading {
  display: flex;
  align-items: center;
}
.app-card__content {
  padding: 10px 16px 10px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.app-card__description {
  margin-top: 6px;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  line-height: 20px;
}
.app-card__description p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--app-card-description);
  margin: 0;
}
.app-card__chip {
  margin-left: 6px;
  margin-top: -1px;
}
.app-card__icon-verified {
  margin-left: 6px;
  color: var(--app-icon-verified-color);
  transform: translateY(-1px);
  width: 16px;
  height: 16px;
}
.app-card__badge {
  text-transform: uppercase;
}
.app-card__badge--hot {
  background: rgba(223, 137, 80, 0.24);
  color: rgb(223, 137, 80);
}
.app-card__badge--new {
  background: var(--app-card-badge-new-background);
  color: #54B893;
}
@media screen and (max-width: 480px) {
  .app-card {
    min-height: 96px;
  }
  .app-card__icon {
    width: 64px;
    height: 64px;
    display: flex;
    border-radius: 13px;
  }
  .app-card__icon-wrapper {
    padding: 16px 0 16px 16px;
    width: auto;
  }
  .apps-list__card {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }
}
.ui-inline-nft {
  display: flex;
  align-items: center;
  background: var(--nft-preview-background);
  border-radius: 4px;
  margin: -4px 0;
  color: inherit;
  transition: 0.2s;
}
.ui-inline-nft__left {
  width: 26px;
  height: 26px;
  border-radius: 3px 0 0 3px;
  overflow: hidden;
}
.ui-inline-nft__title {
  padding: 5px 10px;
  font-size: 13px;
}

.ui-inline-nft:hover {
  background: var(--nft-preview-hover-background);
  color: var(--nft-preview-hover-color);
  text-decoration: none;
}

.ui-inline-nft-image {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--card-border-color);
  color: var(--body-text-color);
}
.ui-inline-nft-image__img {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.ui-inline-nft-image__svg {
  width: 14px;
  height: 14px;
  opacity: 0.8;
  z-index: 100;
}
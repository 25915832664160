.tx-row-msg-action {
  font-size: 13px;
  display: flex;
  align-items: center;
  background: var(--card-row-separator);
  padding: 4px 8px 4px 6px;
  margin: -6px 8px;
  border-radius: 8px;
  cursor: help;
}
.tx-row-msg-action--single {
  margin-right: -1px;
}
.tx-row-msg-action__icon {
  fill: currentColor;
  opacity: 0.6;
  margin-right: 6px;
}
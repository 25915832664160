.ui-settings-section {
  margin: 12px 18px;
}
.ui-settings-section__title {
  padding: 4px 2px 2px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  opacity: 0.6;
}
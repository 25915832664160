.t-selector {
  display: flex;
  line-height: 24px;
  width: fit-content;
  align-items: center;
  max-height: 40px;
  position: relative;
  padding: 3px;
  background: var(--body-light-muted-color);
  border-radius: 12px;
}
.t-selector__item {
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 20px;
  color: var(--selected-big-text);
  cursor: pointer;
  font-weight: 500;
  height: 100%;
}
.t-selector__item-icon {
  margin-right: 8px;
}
.t-selector__item--selected {
  color: var(--selector-big-selected-text);
}
.t-selector__item-name {
  z-index: 10003;
}
.t-selector__selected-background {
  position: absolute;
  bottom: 3px;
  height: calc(100% - 6px);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  background: var(--selector-active);
  z-index: 10002;
  width: calc(100% + 3px);
}

@media screen and (max-width: 991px) {
  .t-selector {
    padding: 0;
    border-radius: 12px;
    height: 100%;
    font-size: 14px;
  }
  .t-selector__selected-background {
    border-radius: 10px;
  }
}
@media screen and (max-width: 480px) {
  .t-selector {
    padding: 0;
    border-radius: 12px;
  }
  .t-selector__item {
    padding: 8px 16px;
  }
  .t-selector__border {
    height: calc(100% - 2px);
    bottom: -1px;
  }
}
.page-app {
  padding-top: 16px;
}

.page-app-block-similar {
  margin-top: 40px;
}
.page-app-block-similar__title {
  text-transform: uppercase;
  font-size: 16px;
  color: #858585;
  font-weight: 500;
  margin-bottom: 20px;
}
.nft-preview-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.nft-preview-error {
  border-radius: 0.52rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  box-shadow: 0 0 0 1px var(--card-border-color) inset;
  background: var(--nft-preview-error-card-background);
}
.nft-preview-error__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding-top: 12px;
  height: 100%;
}
.nft-preview-error__image {
  background-color: transparent !important;
}
.nft-preview-error__icon {
  width: 32px;
  margin-bottom: 16px;
  fill: currentColor;
  opacity: 0.8;
}
.nft-preview-error__text {
  font-size: 15px;
  margin: 0 8px;
  font-weight: 500;
}
.nft-preview-error__button {
  padding: 10px 12px 10px 18px;
  border-radius: 24px;
  margin-top: -1px;
  cursor: pointer;
  display: inline-flex;
  transition: 0.1 0.8s all ease;
}
.nft-preview-error__button:hover {
  background: rgba(0, 0, 0, 0.4);
}
.nft-preview-error__button svg {
  margin-left: -6px;
  margin-right: 6px;
  fill: currentColor;
}
.page-apps {
  margin-top: 6px;
}
.page-apps__content {
  padding-left: 20px;
}
.page-apps__search-filter-wrapper {
  display: flex;
  gap: 15px;
}
.page-apps__search {
  margin-bottom: 20px;
}

@media screen and (max-width: 1240px) {
  .page-apps__content {
    padding: 0 8px;
    border-radius: 0;
  }
}
@media screen and (max-width: 480px) {
  .page-apps__content {
    padding: 0;
    margin: 0 -7px;
  }
}
.vote-casting-button__icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.vote-casting-container {
  margin: 0 16px;
}
.vote-casting-container__buttons {
  margin: 16px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 14px;
}
.vote-casting-container__buttons .bright-blue-button {
  font-size: 24px;
}
.vote-casting-container__text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0;
  padding: 14px 0;
}
.vote-casting-container__text svg {
  fill: currentColor;
  flex-shrink: 0;
  margin-right: 5px;
  width: 14px;
  height: 14px;
}

@media all and (max-width: 480px) {
  .vote-casting-container {
    margin: 0 10px;
  }
  .vote-casting-container__buttons .bright-blue-button {
    font-size: 18px;
  }
  .vote-casting-container__text {
    padding: 10px 0 14px;
    font-size: 13px;
  }
  .vote-casting-container__text svg {
    display: none;
  }
}
.vote-results-bar-container {
  padding: 16px 18px 8px;
}

.vote-results-bar {
  display: flex;
  align-items: center;
  background: var(--stacked-bar-background);
  height: 12px;
  width: 100%;
  border-radius: 36px;
  overflow: hidden;
}
.vote-results-bar__stripe {
  width: 24%;
  height: 100%;
  border-right: 1px solid var(--stacked-bar-background);
  box-sizing: border-box;
  min-width: 2px;
  transition: 0.4s width ease;
}
.vote-results-bar__stripe:first-child {
  min-width: 3px;
}
.vote-results-bar__stripe--green {
  background: var(--stacked-bar-green);
}
.vote-results-bar__stripe--red {
  background: var(--stacked-bar-red);
}

.vote-results-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-top: 8px;
  flex-wrap: wrap;
}
.vote-results-legend__result {
  display: flex;
  align-items: center;
  margin-right: 24px;
  line-height: 1.8;
}
.vote-results-legend__result:last-child {
  margin-right: 0;
}
.vote-results-legend__ricegrain {
  width: 10px;
  height: 4px;
  margin-right: 6px;
  display: inline-block;
  border-radius: 18px;
}
.vote-results-legend__ricegrain--red {
  background: var(--stacked-bar-red);
}
.vote-results-legend__ricegrain--green {
  background: var(--stacked-bar-green);
}
.vote-results-legend__ricegrain--meh {
  background: var(--stacked-bar-meh);
}
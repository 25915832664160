.t-chip {
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 0 6px;
  height: 16px;
  font-size: 11px;
  line-height: 17px;
  vertical-align: middle;
}
.item__name {
  color: var(--app-info-name);
  font-weight: 400;
}

.item__value {
  margin-top: 8px;
  font-weight: 500;
}
.item__value .value-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.item__value svg {
  color: var(--icon-color);
  width: 16px;
  height: 16px;
}
.item__value svg:hover {
  color: var(--icon-hover-color);
}
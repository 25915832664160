.config-content-address__title {
  margin-bottom: 6px;
  font-weight: bold;
  text-transform: uppercase;
}
.config-content-address__icon {
  width: 14px;
  height: 14px;
  transform: translateY(1px);
  margin-right: 3px;
}

.config-address-items {
  display: flex;
  align-items: center;
}
.theme-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 12px 0;
}

.theme-selector-box {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  flex: 1;
  border-width: 2px;
  border-style: solid;
  border-color: var(--theme-selector-auto-border-color);
  background: var(--theme-selector-auto-background);
  color: var(--theme-selector-auto-text-color);
}
.theme-selector-box .theme-selector-box__text, .theme-selector-box .theme-selector-box__image {
  border-color: var(--theme-selector-auto-separator-color);
}
.theme-selector-box--theme-light {
  background: var(--theme-selector-light-background);
  border-color: var(--theme-selector-light-border-color);
  color: var(--theme-selector-light-text-color);
}
.theme-selector-box--theme-light .theme-selector-box__text, .theme-selector-box--theme-light .theme-selector-box__image {
  border-color: var(--theme-selector-light-separator-color);
}
.theme-selector-box--theme-dark {
  background: var(--theme-selector-dark-background);
  border-color: var(--theme-selector-dark-border-color);
  color: var(--theme-selector-dark-text-color);
}
.theme-selector-box--theme-dark .theme-selector-box__text, .theme-selector-box--theme-dark .theme-selector-box__image {
  border-color: var(--theme-selector-dark-separator-color);
}
.theme-selector-box--selected {
  border-color: var(--blue-bright);
}
.theme-selector-box__tick {
  position: absolute;
  left: -9px;
  top: -9px;
  width: 22px;
  height: 22px;
  background: var(--blue-bright);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme-selector-box__tick__icon {
  fill: #FFF;
  width: 14px;
}
.theme-selector-box__image {
  width: 72px;
  height: 59px;
  margin: 20px auto;
  border-radius: 4px;
  border: 2px solid;
}
.theme-selector-box__text {
  padding: 12px;
  text-align: center;
  border-width: 1px 0 0 0;
  border-style: solid;
}

@media all and (max-width: 480px) {
  .theme-selector {
    flex-direction: column;
    gap: 12px;
  }
  .theme-selector-box {
    width: 100%;
    flex-direction: row;
  }
  .theme-selector-box__image {
    margin: unset;
    margin: 12px 16px;
    height: 46px;
    width: auto;
  }
  .theme-selector-box__text {
    border-left-width: 1px;
    border-top-width: 0;
    align-self: center;
    padding: 8px 16px;
  }
}
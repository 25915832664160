.block-info-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 82px;
  grid-gap: 18px;
  margin-bottom: 18px;
}
.block-info-section .information-block {
  margin-top: 0 !important;
}

.block-chains-section {
  display: grid;
  width: 100%;
  align-items: stretch;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
}
.block-chains-section .card + .card {
  margin: 0;
}

@media screen and (max-width: 900px) {
  .block-info-section {
    grid-template-columns: repeat(2, 1fr);
  }
  .block-chains-section {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 480px) {
  .block-info-section {
    grid-template-columns: 1fr;
  }
}
.stats-section {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: 120px 370px 340px repeat(2, 380px);
  grid-gap: 24px 18px;
}

.chart-box {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 16px;
  flex: 1;
}
.chart-box header {
  margin-bottom: 8px;
  color: var(--card-header-color);
}
.chart-box__value {
  font-size: 28px;
}
.chart-box footer {
  opacity: 0.3;
}

.information-block[data-index="0"] {
  grid-column: 1/span 3;
}

.information-block[data-index="1"] {
  grid-column: 4/span 3;
}

.information-block[data-index="2"] {
  grid-column: 7/span 3;
}

.information-block[data-index="3"] {
  grid-column: 10/span 3;
}

.ton-icon {
  fill: currentColor;
  width: 28px;
  height: 28px;
  margin-right: 2px;
  opacity: 0.3;
  transform: translate(-3px, 3px);
}

.cotract-types-container {
  grid-column: 1/6;
}

.address-count-container {
  grid-column: 6/span 7;
}

.price-container,
.transaction-count-container,
.staking-container,
.validation-status-container {
  grid-column: 1/13;
}

@media screen and (max-width: 1099px) {
  .stats-section {
    grid-template-rows: 108px 108px 400px 360px repeat(2, 300px) 400px auto;
  }
  .information-block[data-index="0"],
  .information-block[data-index="2"] {
    grid-column: 1/7;
  }
  .information-block[data-index="1"],
  .information-block[data-index="3"] {
    grid-column: 7/13;
  }
  .cotract-types-container,
  .address-count-container {
    grid-column: 1/13;
  }
}
@media screen and (max-width: 599px) {
  .stats-section {
    grid-template-rows: 108px 108px 108px 108px 520px 570px repeat(2, 280px) 520px auto;
    grid-gap: 14px;
  }
  .information-block[data-index="0"],
  .information-block[data-index="1"],
  .information-block[data-index="2"],
  .information-block[data-index="3"] {
    grid-column: 1/13;
  }
}
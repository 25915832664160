.app-screenshot {
  border-radius: 12px;
  position: relative;
  scroll-snap-align: center;
}
.app-screenshot:first-child {
  margin-left: 25px;
}
.app-screenshot__image {
  border-radius: 12px;
  object-fit: cover;
  object-position: center top;
}
.app-screenshot__image--portrait {
  max-width: 372px;
  height: 372px;
}
.app-screenshot__image--landscape {
  max-width: 659px;
  height: 372px;
}
.app-screenshot__image--loading {
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 210px;
  height: 372px;
}

.app-screenshot-skeleton {
  width: 210px;
  height: 372px;
}
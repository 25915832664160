.chart-bar {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.chart-bar__graph {
  position: relative;
  margin: 0 0 0 -8px;
  box-sizing: border-box;
  flex-grow: 1;
  color: #313131;
}
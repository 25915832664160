.interval-selector {
  display: flex;
  align-items: center;
  padding: 3px;
  margin: -3px;
  border-radius: 6px;
  background: var(--chart-interval-selector-background);
  color: var(--chart-interval-selector-color);
  text-transform: none;
  margin-left: auto;
}
.interval-selector__item {
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}
.interval-selector__item--active {
  background: var(--chart-interval-selector-item-background);
  color: var(--chart-interval-selector-item-active-color);
}
.page-apps-search {
  position: relative;
  width: calc(100% - 4px);
}

.page-apps-search-input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  color: inherit;
  position: relative;
  border-radius: 12px;
  background: var(--indexpage-search-background-color);
  border: 2px solid var(--card-border-color);
}
.page-apps-search-input--focused {
  background: var(--indexpage-search-background-color);
  border-color: var(--app-search-field-focused-border-color) !important;
  background: var(--indexpage-search-background-color);
}
.page-apps-search-input--expanded {
  border-radius: 12px 12px 0 0;
}
.page-apps-search-input__icon {
  width: 21px;
  height: 14px;
  margin: 16px 9px 16px 16px;
  color: var(--app-search-icon);
}
.page-apps-search-input__input {
  flex: 1;
  border: none;
  background: transparent;
  color: inherit;
  outline: none;
  font-size: 16px;
  padding: 0;
  height: 100%;
}
.page-apps-search-input__input::placeholder {
  color: #939394;
}
.page-apps-search-input__input::-webkit-input-placeholder {
  color: #939394;
}
.page-apps-search-input__right {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  fill: var(--body-text-color);
}
.page-apps-search-input__right:empty {
  width: 0;
}

.page-apps-search-suggestions {
  border: 2px solid var(--card-border-color);
  border-top: none;
  position: absolute;
  background: var(--card-background);
  width: 100%;
  z-index: 10000;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
}
.page-apps-search-suggestions__suggestion {
  text-decoration: none;
  color: inherit;
}
.page-apps-search-suggestions__suggestion:hover {
  text-decoration: none;
}
.page-apps-search-suggestions__box-empty {
  padding: 20px;
}
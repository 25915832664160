.user-nfts {
  padding: 24px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
@media all and (max-width: 1200px) {
  .user-nfts {
    padding: 18px;
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }
}
@media all and (max-width: 900px) {
  .user-nfts {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media all and (max-width: 600px) {
  .user-nfts {
    grid-template-columns: 1fr 1fr;
  }
}
@media all and (max-width: 480px) {
  .user-nfts {
    padding: 14px;
    grid-column-gap: 14px;
    grid-row-gap: 14px;
  }
}

.user-nfts-empty-panel {
  padding: 30px 24px 24px;
  font-weight: 500;
  text-align: center;
}

.user-nft {
  position: relative;
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  background: var(--user-nft-list-item-background);
  box-shadow: 1px 1px var(--user-nft-list-item-box-shadow-blur) rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  overflow: hidden;
  transition: 0.2s background ease;
}
.user-nft:hover {
  text-decoration: none;
}
@media (hover: hover) {
  .user-nft:hover {
    background: var(--user-nft-list-item-hover-background);
  }
  .user-nft:hover .user-nft__image__img {
    transform: scale(1.08);
  }
}
.user-nft--skeleton {
  pointer-events: none;
  user-select: none;
}
.user-nft__image {
  font-size: 0;
  width: 100%;
  overflow: hidden;
  display: block;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  aspect-ratio: 1/1;
}
.user-nft__image__img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: none;
  transition: 0.2s transform ease;
}
.user-nft__footer {
  padding: 12px 12px 10px;
}
.user-nft__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
}
.user-nft__collection {
  padding: 4px 4px 8px 4px;
  margin: -4px;
  font-weight: normal;
  font-size: 13px;
  color: var(--user-nft-list-item-text-muted-color);
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.user-nft__collection__text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-nft__collection__icon {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-left: 0.3em;
  fill: var(--blue-bright);
}

@media all and (max-width: 480px) {
  .user-nft__footer {
    padding: 10px;
  }
  .user-nft__name {
    font-size: 14px;
  }
  .user-nft__collection {
    font-size: 11px;
  }
}
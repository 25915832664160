.app {
  padding: 25px 25px 0 25px;
  background: var(--card-background);
  border: 1px solid var(--card-border-color);
  border-radius: 13px;
  margin-top: 16px;
  position: relative;
}

.app-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 25px;
}

.app-error-title {
  color: var(--apps-navigation-inactive-color);
  margin-bottom: 20px;
}

.app-error-button .btn {
  display: block;
  line-height: 40px;
  padding: 0 15px;
  background: var(--big-blue-button-background);
  color: #FFF;
  font-weight: 500;
  font-size: 14px;
  border-radius: 6px;
  text-align: center;
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
}
.app-error-button .btn:hover {
  text-decoration: none;
  background: var(--big-blue-button-hover-background);
}

@media screen and (max-width: 480px) {
  .app {
    padding: 24px 16px 0 16px;
    border: none;
    border-radius: 0;
    margin-left: -16px;
    margin-top: 12px;
    width: 100%;
    border-top: 1px solid var(--card-border-color);
    border-bottom: 1px solid var(--card-border-color);
  }
}
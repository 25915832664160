.ui-round-image {
  color: var(--ui-round-image-color);
  flex-shrink: 0;
}
.ui-round-image__image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ui-round-image-stub {
  border-radius: 50%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  border: 3px solid currentColor;
  box-sizing: border-box;
}
.ui-round-image-stub__icon {
  width: 44%;
  margin-bottom: -6%;
  fill: currentColor;
}

.ui-round-image--tiny .ui-round-image-stub {
  border-width: 1px;
}
.ui-round-image--tiny .ui-round-image-stub__icon {
  width: 56%;
}
.t-breadcrumbs {
  display: flex;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  overflow-x: overlay;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.t-breadcrumbs-crumb {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--breadcrumbs-text-color);
}
.t-breadcrumbs-crumb :last-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.t-breadcrumbs-crumb :last-child span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.t-breadcrumbs-crumb a, .t-breadcrumbs-crumb span {
  color: var(--breadcrumbs-text-color);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s;
}
.t-breadcrumbs-crumb a:hover {
  color: var(--body-text-color);
  text-decoration: none;
}
.t-breadcrumbs-crumb__chevron {
  color: var(--breadcrumbs-text-color);
  width: 5px;
  height: 9px;
}
.t-breadcrumbs-crumb--selected span {
  color: var(--body-text-color);
  cursor: default;
}
@charset "UTF-8";
.page-address-contract-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.page-address-contract-info__link {
  display: flex;
  align-items: center;
}
.page-address-contract-info .ui-round-image {
  margin: -8px 6px -8px 0;
  width: 20px;
  height: 20px;
  color: inherit;
}
.page-address-contract-info__block {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}
.page-address-contract-info__block::after {
  content: "•";
  padding: 0 6px;
  color: var(--body-muted-text-color);
}
.page-address-contract-info__block:last-child::after {
  display: none;
}
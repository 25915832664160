.last-blocks-mini-table {
  overflow: hidden;
  border-collapse: collapse;
  width: 100%;
}
.last-blocks-mini-table td, .last-blocks-mini-table th {
  white-space: nowrap;
  overflow: hidden;
  padding: 10px 16px;
  border-top: 1px solid var(--card-border-color);
  font-size: 14px;
  max-width: 250px;
  text-align: center;
}
.last-blocks-mini-table td:first-child, .last-blocks-mini-table th:first-child {
  text-align: left;
}
.last-blocks-mini-table th {
  font-weight: 500;
  border: none;
}
.last-blocks-mini-table thead {
  background-color: var(--tx-table-thead-alt-background);
  color: #6c757e;
  border: none;
}
.ui-inline-jetton {
  display: inline-flex;
  align-items: center;
}
.ui-inline-jetton__value {
  margin-right: 4px;
}
.ui-inline-jetton__link {
  display: flex;
  align-items: center;
}
.ui-inline-jetton__avatar {
  margin: -4px -1px -4px 6px;
  width: 20px;
  height: 20px;
}
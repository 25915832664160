.chart-pie {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 100px;
  max-width: 280px;
  aspect-ratio: 1/1;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chart-pie__canvas {
  z-index: 2000;
  position: relative;
  color: var(--card-background);
  max-height: 100%;
}

.chart-pie-inner {
  position: absolute;
  z-index: 1000;
  aspect-ratio: 1/1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20%;
  border-radius: 50%;
  text-align: center;
  margin: auto;
}
.chart-pie-inner__counter {
  font-size: 22px;
}
.chart-pie-inner__explanation {
  color: #adadab;
  margin-top: 4px;
  font-size: 14px;
}
.suspended-table__index-cell {
  padding-right: 4px !important;
}
.suspended-table__total {
  background-color: var(--tx-table-thead-alt-background);
}

.suspended-info {
  padding: 0 4px;
}
.suspended-info__title {
  font-size: 28px;
  margin-bottom: 10px;
}
.suspended-info__lead {
  margin-top: 0;
  margin-bottom: 22px;
  font-size: 16px;
  line-height: 1.5;
}
.validators-config-table {
  width: 100%;
}
.validators-config-table thead {
  background-color: transparent !important;
}
.validators-config-table th {
  border-radius: 0.5rem 0.5rem 0 0;
  padding-top: 0;
}
.validators-config-table th,
.validators-config-table td {
  padding: 10px 12px !important;
}
.validators-config-table td {
  border-top: none;
}
.validators-config-table tr > td:first-child {
  width: 26px;
}
.validators-config-table .copy-adnl-icon {
  opacity: 0;
  transition: 0.2s;
}
.validators-config-table .adnl-cell:hover {
  cursor: default;
}
.validators-config-table .adnl-cell:hover .copy-adnl-icon {
  opacity: 1;
}
.verifier-admonition {
  background: var(--code-viewer-file-selector-background-color);
  display: flex;
  padding: 12px;
  align-items: flex-start;
  border: 1px solid var(--code-viewer-file-selector-border-color);
  border-radius: 8px;
  margin: 16px 12px 14px 12px;
}
.verifier-admonition__icon {
  fill: #888;
  width: 36px;
  height: 36px;
  padding: 4px 0 0 4px;
  flex-shrink: 0;
}
.verifier-admonition__icon--verified {
  fill: var(--green-bright);
}
.verifier-admonition__right {
  padding-left: 14px;
}
.verifier-admonition__title {
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 6px 0;
  padding: 0;
}
.verifier-admonition__text {
  line-height: 1.5;
  hyphens: auto;
}

.verifier-admonition-meta {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 4px;
}
.verifier-admonition-meta__label {
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-family: "Ubuntu Mono", monospace;
  opacity: 0.85;
  line-height: 1.4em;
  margin-top: 12px;
}
.verifier-admonition-meta__label__icon {
  margin-top: 0.2em;
  flex-shrink: 0;
  margin-right: 6px;
  opacity: 0.6;
  width: 16px;
  height: 16px;
  fill: currentColor;
  align-self: flex-start;
}

@media all and (max-width: 600px) {
  .verifier-admonition__icon {
    display: none !important;
  }
  .verifier-admonition__right {
    padding-left: 0;
  }
  .verifier-admonition__title {
    font-size: 18px;
    margin-bottom: 12px;
  }
}
@charset "UTF-8";
.search-input {
  display: flex;
  align-items: stretch;
  width: 100%;
  border: none;
  color: var(--body-text-color);
  box-sizing: border-box;
  position: relative;
  z-index: 200;
  transition: 0.06s border-color ease;
}
.search-input--expanded {
  background: var(--indexpage-search-background-color) !important;
  border: 2px solid var(--card-border-color) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.search-input:focus-within {
  border-color: #2575ed !important;
}
.search-input__loopa {
  width: 20px;
  height: 20px;
  opacity: 0.35;
  z-index: 100;
  align-self: center;
  flex-shrink: 0;
  transition: 0.1s all ease;
  overflow: hidden;
  fill: currentColor;
}
.search-input__loopa--hidden {
  width: 0;
}
.search-input__input {
  font-size: 1em;
  appearance: none;
  border: none;
  display: block;
  padding: 0;
  width: 100%;
  background: transparent;
  text-overflow: ellipsis;
  color: inherit;
}
.search-input__input::placeholder {
  color: #777;
}
.search-input__input::-webkit-input-placeholder {
  color: #777;
}

.search-input-controls {
  display: flex;
  align-items: center;
  min-height: 100%;
  z-index: 1000;
  box-sizing: border-box;
  padding: 0.25em 0.65em;
  gap: 0.5em;
}
.search-input-controls__loader {
  width: 18px;
  height: 18px;
  z-index: 900;
}
.search-input-controls__go {
  border-radius: 9px;
  background: #2575ed;
  color: #FFF;
  height: 100%;
  min-width: 64px;
  font-size: 16px;
  cursor: pointer;
  margin-right: -0.4em;
}
.search-input-controls__close {
  width: 1.625em;
  height: 1.625em;
  fill: currentColor;
  opacity: 0.7;
  padding: 8px;
  margin: -8px;
  cursor: pointer;
  transition: 0.1s opacity ease;
}
.search-input-controls__close:hover {
  opacity: 1;
}

.search {
  width: 100%;
  position: relative;
}

.search-results {
  z-index: 100;
  width: 100%;
  position: absolute;
  top: 100%;
  border: 2px solid var(--card-border-color);
  border-top: none;
  box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
  color: var(--body-text-color);
  box-sizing: border-box;
  font-size: 16px;
  background: var(--indexpage-search-background-color);
  border-radius: 0 0 12px 12px;
}
.search-results__list {
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}
.search-results__link {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  line-height: 1.4;
  color: inherit;
  background: transparent;
}
.search-results__link:hover, .search-results__item--selected .search-results__link {
  background: rgba(142, 142, 142, 0.16);
  text-decoration: none;
}
.search-results__domain {
  font-size: 15px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}
.search-results__domain__tld {
  font-weight: normal;
  opacity: 0.6;
}
.search-results__name {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
}
.search-results__name__title {
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-results__name__category {
  display: inline-flex;
  align-items: center;
  font-weight: normal;
  opacity: 0.45;
  font-size: 12px;
}
.search-results__name__category::before {
  opacity: 0.65;
  content: "·";
  padding: 0 6px;
}
.search-results__address {
  font-size: 13px;
  opacity: 0.7;
}

.search-result {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.search-result--compact {
  padding-left: 2px;
}
.search-result__left {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  position: relative;
}
.search-result--compact .search-result__left {
  display: none;
}
.search-result__address-icon {
  width: 42px;
  height: 42px;
  border-radius: 8px;
  background: var(--card-border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.search-result__address-icon__svg {
  width: 24px;
  height: 24px;
  fill: currentColor;
  margin-right: -1px;
}
.search-result--offsetted .search-result__address-icon {
  margin: 0 9px;
}
.search-result--compact .search-result__address-icon {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  box-shadow: 0 0 0 4px #1d1d1d;
}
.search-result--compact .search-result__address-icon__svg {
  width: 60%;
  height: 60%;
  margin-bottom: -1px;
}
.search-result__image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.search-result__right {
  overflow: hidden;
}
.search-result--compact .search-result__right {
  display: flex;
  align-items: center;
}
.search-result__buttons {
  flex-grow: 0;
  display: flex;
  align-items: center;
  margin-right: -4px;
  padding-left: 18px;
  margin-left: auto;
}

.search-result-button {
  fill: var(--badge-grey-color);
  padding: 8px;
  margin: -8px;
  border-radius: 50%;
}
.search-result-button:hover {
  fill: var(--badge-red-color);
}
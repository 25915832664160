.ui-qr {
  background: #FFF;
  color: #FFF;
  border-radius: 4px;
  padding: 6px;
  position: relative;
  font-size: 0;
}
.ui-qr__stub {
  background: var(--body-background);
}
.ui-qr__logo {
  fill: #111;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 40%;
  paint-order: stroke;
  transform: translateX(-50%) translateY(-50%);
}
.ui-qr__logo--cat {
  margin-top: -2%;
}
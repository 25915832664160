.apps-list__card {
  margin-bottom: 20px;
  color: inherit;
}
.apps-list__filter-selector {
  display: flex;
  align-items: center;
}
.apps-list__not-found {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .t-col:first-child .apps-list__card-link .apps-list__card {
    border-top: 1px solid var(--card-border-color);
  }
  .t-col .apps-list__card-link .apps-list__card {
    border-top: none;
  }
  .apps-list {
    margin-top: 12px;
  }
  .apps-list__card {
    margin: 0 -16px;
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
  }
}
.btn {
  min-width: 64px;
  padding: 0 16px;
  height: 36px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 500;
  border-radius: 6px;
}
.btn--block {
  width: 100% !important;
}
.btn--xs {
  height: 20px;
  min-width: 36px;
  padding: 0 8px;
  font-size: 0.685rem;
}
.btn--sm {
  height: 28px;
  padding: 0 12px;
  min-width: 50px;
  font-size: 0.75rem;
}
.btn--lg {
  font-size: 1rem;
  height: 44px;
  min-width: 78px;
  padding: 0 20px;
}
.btn--xl {
  padding: 1.5rem 2rem;
}
.btn--xxl {
  padding: 1.75rem 2.25rem;
}
.btn--large {
  padding: 20px 40px;
  font-size: 18px;
}
.btn--rounded {
  border-radius: 8px;
}
.btn__icon {
  margin-right: 8px;
}
.btn__text {
  font-weight: bold;
}
.btn--primary {
  background-color: var(--m3-container-primary);
  color: white;
}
.btn--primary:hover {
  background-color: #0056b3;
}
.btn--grey-lighten {
  background-color: #FAFAFA;
  color: #4f4f4f;
}
.btn--grey-lighten:hover {
  background-color: #F0F0F0;
}
.btn--secondary {
  background-color: #6c757d;
  color: white;
}
.btn--secondary:hover {
  background-color: #5a6268;
}
.btn--blue {
  background: var(--big-blue-button-background);
  color: #FFF;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.15s background ease;
  text-decoration: none;
}
.btn--blue:hover {
  text-decoration: none;
  background: var(--big-blue-button-hover-background);
}
.btn--blue--disabled {
  background: var(--big-blue-button-disabled-background);
  color: var(--big-blue-button-disabled-color);
  pointer-events: none;
}
.page-validators-info-card {
  margin-top: 0 !important;
  margin-bottom: 18px;
  flex-grow: 1;
}

.validators-table thead {
  background-color: var(--tx-table-thead-alt-background);
}
.validators-table th {
  border-radius: 0.5rem 0.5rem 0 0;
}
.validators-table th, .validators-table td {
  padding: 10px 12px !important;
}
.validators-table tr > th:first-child, .validators-table tr > td:first-child {
  width: 26px;
}
.validators-table tr > th:last-child, .validators-table tr > td:last-child {
  width: 40px;
}
.validators-table .copy-adnl-icon {
  opacity: 0;
  transition: 0.2s;
}
.validators-table .adnl-cell:hover {
  cursor: default;
}
.validators-table .adnl-cell:hover .copy-adnl-icon {
  opacity: 1;
}
.verifier-sources {
  display: flex;
  flex-direction: row;
}
.verifier-sources__files {
  margin: 0 0 12px 12px;
  width: 240px;
  flex-shrink: 0;
}
.verifier-sources__files__elevator {
  position: sticky;
  top: 12px;
}
.verifier-sources__code {
  flex-grow: 1;
  overflow: auto;
  margin: 0 12px 12px 12px;
}

.source-viewer-code__pre {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.hljs-hack-lines {
  padding: 0 10px 0 0;
  text-align: right;
  color: var(--body-muted-text-color);
}

.hljs-hack-code {
  padding: 0 10px 0 4px;
}

@media all and (max-width: 480px) {
  .verifier-sources {
    flex-direction: column;
  }
  .verifier-sources__files {
    width: unset;
    margin-right: 12px;
  }
  .verifier-sources__code {
    margin-top: 0;
  }
}
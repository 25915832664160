.app__description {
  max-width: 580px;
  font-weight: 400;
  line-height: 24px;
  color: var(--body-text-color);
}
.app__description--loading {
  width: 100%;
  min-height: 115px;
  margin-top: 24px;
}
.app__description-container {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  display: -webkit-box;
  margin-bottom: -20px;
  overflow: hidden;
  position: relative;
  z-index: 800;
}
.app__description-container--blur::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 78px;
  background: linear-gradient(to top, var(--card-background) 20%, transparent);
  pointer-events: none; /* so the gradient doesn't block clickable elements */
}
.app__description-container--expanded {
  display: block;
}
.app__description-container p:first-child {
  margin-top: 0;
}
.app__description-container p:last-child {
  margin-top: 0;
}
.app__description-expander {
  margin-top: -10px;
  margin-bottom: -10px;
  position: relative;
  font-weight: 500;
  color: var(--blue-bright);
  cursor: pointer;
  z-index: 900;
}
.app__description-expander:hover {
  text-decoration: underline;
}
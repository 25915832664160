.tx-status {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.tx-status--failed {
  color: var(--page-tx-status-error-color);
}
.tx-status--success {
  color: var(--page-tx-status-success-color);
}
.tx-status__icon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.tx-flow-schematics {
  display: flex;
  align-items: center;
  border: 1px solid var(--page-tx-flow-diagram-border-color);
  overflow: hidden;
  border-radius: 6px;
}

.tx-flow-schematics-step {
  display: flex;
  align-items: center;
}
.tx-flow-schematics-step__inner {
  padding: 4px 10px;
  display: flex;
  flex-direction: column;
}
.tx-flow-schematics-step__phase {
  font-size: 12px;
}
.tx-flow-schematics-step::after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border-color: var(--page-tx-flow-diagram-border-color);
  border-width: 0 1px 1px 0;
  border-style: solid;
  transform: rotate(-45deg);
  margin: 0 8px 0 -28px;
  user-select: none;
  pointer-events: none;
}
.tx-flow-schematics-step:last-child::after {
  display: none;
}
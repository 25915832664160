.chart-change-indicator {
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-left: -1px;
}
.chart-change-indicator--red {
  color: #f74b4c;
}
.chart-change-indicator--red .chart-change-indicator__arrow {
  transform: none;
  background: rgba(247, 75, 76, 0.231372549);
}
.chart-change-indicator--red .chart-change-indicator__arrow__svg {
  bottom: -1px;
}
.chart-change-indicator--green {
  color: #42bd62;
}
.chart-change-indicator--green .chart-change-indicator__arrow {
  transform: rotate(180deg);
  background: rgba(66, 189, 98, 0.231372549);
}
.chart-change-indicator__arrow {
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  padding: 0;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
.chart-change-indicator__arrow__svg {
  position: relative;
  width: 10px;
  height: 9px;
}
.chart-change-indicator__range {
  color: var(--body-muted-text-color) !important;
  margin-left: 0.8ex;
  opacity: 0.7;
}
.param-skeleton-container {
  margin-bottom: 30px;
}

.param-skeleton {
  margin-bottom: 10px;
}
.param-skeleton__header {
  width: 200px;
  height: 24px;
}
.param-skeleton__number {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.param-skeleton__header-container {
  display: flex;
}
.param-skeleton__sub {
  width: 75%;
}
.param-skeleton__viewer {
  margin-top: 22px;
  height: 80px;
}
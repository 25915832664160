.nft-metadata-badge {
  display: flex;
  align-items: center;
}
.nft-metadata-badge__label {
  display: inline-flex;
  align-items: center;
}
.nft-metadata-badge__label__icon {
  fill: currentColor;
  margin-right: 0.35em;
}
.nft-metadata-badge__label__text {
  font-weight: 500;
  margin-right: 0.35em;
}
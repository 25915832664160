.page-nominator-top {
  display: flex;
  flex-direction: row;
}

.page-nominator-qr-card {
  padding: 14px;
  margin-right: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: space-between;
}
.page-nominator-qr-card__qr-code {
  border-radius: 4px;
  margin-bottom: 14px;
}
.page-nominator-qr-card__text {
  margin: 10px auto -4px;
  font-size: 13px;
  text-align: center;
  width: 100%;
  overflow: hidden;
  max-width: 235px;
  display: block;
}
.page-nominator-qr-card__text b {
  font-weight: 500;
}

.page-nominator-info-card {
  margin-top: 0 !important;
  flex-grow: 1;
}

.page-nominator-validator-activity {
  width: 16px;
  height: 16px;
  margin-right: 2px;
  margin-bottom: -1px;
}
.page-nominator-validator-activity--active {
  fill: var(--green-bright);
}
.page-nominator-validator-activity--pending {
  fill: var(--yellow-bright);
}
.page-nominator-validator-activity--inactive {
  fill: var(--badge-grey-color);
}

@media screen and (max-width: 740px) {
  .page-nominator-top {
    flex-direction: column-reverse;
  }
  .page-nominator-qr-card {
    margin: 18px 0 0 0;
  }
  .page-nominator-qr-card__qr-code {
    display: none;
  }
  .page-nominator-qr-card__text {
    max-width: unset;
  }
}
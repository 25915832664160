.nft-item-container {
  display: flex;
  margin-top: 32px;
  flex-direction: row;
}
.nft-item-container__info {
  margin-top: auto;
  flex-grow: 1;
  flex-basis: 100%;
}

.nft-item-transactions-container {
  margin-top: 32px;
}

.nft-image-container {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-right: 32px;
  background-color: var(--ambilight-overlay-color);
  background-size: cover;
  background-position: center;
  background-blend-mode: soft-light;
  border-radius: 16px;
  width: 100%;
  max-width: 420px;
}
.nft-image-container__image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 60vh;
  border-radius: 16px;
  display: block;
  object-fit: contain;
  margin: auto;
}
.nft-image-container__image.skeleton {
  border-radius: 8px;
  background-image: none;
  box-shadow: none;
  aspect-ratio: 1/1;
}

.nft-item-header {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-width: 150px;
}
.nft-item-header__title {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 12px;
  margin-top: 12px;
}
.nft-item-header__description {
  font-size: 17px;
  margin-bottom: 18px;
  line-height: 1.4;
}

.nft-card-collection {
  display: flex;
  align-items: center;
}
.nft-card-collection__thumb {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin: -8px 6px -8px 0;
}

.card-row-inline-pager {
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
}
.card-row-inline-pager__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin: -6px;
  cursor: pointer;
}
.card-row-inline-pager__arrow[disabled] {
  color: #777;
  pointer-events: none;
  opacity: 0.5;
}
.card-row-inline-pager__arrow:hover .card-row-inline-pager__arrow__svg {
  opacity: 1;
}
.card-row-inline-pager__arrow__svg {
  cursor: pointer;
  fill: currentColor;
  width: 12px;
  height: 12px;
  padding: 4px;
  margin: -4px 2px;
  background: var(--card-row-separator);
  opacity: 0.6;
  transition: 0.08s opacity ease-out;
  border-radius: 4px;
}

@media all and (max-width: 800px) {
  .nft-item-container {
    flex-direction: column;
    margin-top: 8px;
  }
  .nft-image-container {
    max-width: unset;
    overflow: hidden;
  }
  .nft-image-container__image {
    width: auto;
    height: 40vh;
    border-radius: 0;
  }
  .nft-item-transactions-container {
    margin-top: 18px;
  }
  .nft-item-header {
    box-sizing: border-box;
    padding: 0 16px;
  }
  .nft-item-header__title {
    margin-top: 18px;
    margin-bottom: 6px;
    font-size: 26px;
  }
  .nft-item-header__description {
    font-size: 16px;
    line-height: 1.4;
  }
  .nft-card-collection__thumb {
    width: 20px;
    height: 20px;
  }
  .card-row-inline-pager__arrow__svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}